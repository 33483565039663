import settings from '../config/'

export const messageTypes = {
  pl: {
    // Tabelki
    scenario: 'Scenariusz',
    description: 'Opis',
    actions: 'Akcje',
    name_name: 'Nazwa',
    role: 'Rola',
    difficulty: 'Poziom trudności',
    machines: 'Maszyny',
    machine_id: 'UUID maszyny',
    answers_split_by_comma: 'Odpowiedzi oddzielone przecinkami',
    back: 'Wstecz',
    time: 'Czas',
    time_hms: 'Czas (GG:MM:SS)',
    data_length: 'Liczba pytań',
    type: 'Typ',
    order: 'Kolejność',
    scenario_type_presentation: 'Prezentacyjny',
    scenario_type_education: 'Edukacyjny',
    scenario_type_recruit: 'Rekrutacyjny',
    scenario_type_hardware: 'Hardware',
    scenario_type_blueteam: 'Blue team',
    machine_name: 'Nazwa maszyny',
    network_name: 'Nazwa sieci',
    event_date: 'Data zdarzenia',
    date: 'Data',
    table_sort_desc: 'Sortuj malejąco',
    table_sort_asc: 'Sortuj rosnąco',
    table_sort_cancel: 'Kliknij aby anulować sortowanie',
    table_pagination_page: '/ strona',
    permission_error: 'Brak uprawnień',
    page_not_found_error: 'Strona nie istnieje',

    congrats: 'Gratulacje!',
    congrats2: 'Udało Ci się!',
    all_flags_done: 'Wszystkie flagi zdobyte :-)',
    solve_more: 'Pamiętaj aby zdobyć wszystkie flagi ponieważ punkty mają znaczenie ;-)',
    submit_flag: 'Prześlij flagę',
    time_to_solve_expired: 'Czas na przesłanie rozwiązania minął, jeśli zdobyłeś flagę możesz sprawdzić jej poprawność ale nie będzie się już liczyć do rankingu.',
    solve_time_will_expire_at: 'Przesłanie rozwiązania dla tego zadania jest ograniczone do',
    solves: 'Rozwiązania',
    solves2: 'Rozwiązań',
    no_solves: 'Brak rozwiązań',
    attached_flags: 'Podpięte flagi',
    value: 'Wartość',
    attached_challenge: 'Podpięte zadanie',
    challenge_details: 'Szczegóły zadania',
    user_points: 'Twoje punkty',

    // universal
    click_to_copy: 'Kliknij aby skopiować',
    copied_to_clipboard: 'Skopiowano do schowka',
    more_info: 'Więcej informacji',
    current: 'bieżące',
    files: 'Pliki',
    file: 'Plik',
    remove_real_file_question: 'Czy na pewno chcesz usunąć ten plik? Ta operacja jest natychmiastowa i nieodwracalna.',
    send_file: 'Prześlij plik',

    // Kreator scenariuszy
    field_required: '*Pole wymagane',
    name_pl: 'Polska nazwa',
    name_en: 'Angielska nazwa',
    desc_pl: 'Polski opis',
    desc_en: 'Angielski opis',
    desc_long_pl: 'Długi opis pl',
    desc_long_en: 'Długi opis en',
    number_of_files: 'Liczba plików',
    file_label_pl: 'Tytuł pliku pl',
    file_label_en: 'Tytuł pliku en',
    file_type: 'Typ pliku',
    file_path_pl: 'Ścieżka pliku pl',
    file_path_en: 'Ścieżka pliku en',
    file_pl: 'Plik pl',
    file_en: 'Plik en',
    file_in_dir: 'plik w katalogu',
    file_path_placeholder: 'np.: %LANG%_filename.pdf',
    active: 'Aktywny',
    active2: 'Aktywne',
    active3: 'Aktywna',
    inactive: 'nieaktywne',
    ctf_start: 'start CTF',
    added_to_map: 'Dodane do mapy',
    s_type: 'Typ scenariusza',
    show: 'Pokaż',
    view_for_roles: 'Widoczne dla ról',
    view_for_stack_types: 'Widoczne dla typów stacka',
    unfreeze_ranking: 'Odmroź ranking',
    recruitment_scenario: 'Scenariusz rekrutacyjny',
    scenario_demo: 'Wersja demo (brak dost. do materiałów)',
    switch_to_this_stack: 'Przełącz do tego stacka',
    your_current_stack: 'Twój obecny stack',
    scenario_reservable: 'Rezerwowalny przez użytkownika',

    reserved_to: 'Zarezerwowany do',
    reserved_by: 'przez',
    active_reservations: 'Aktywnych rezerwacji',

    // search
    table_search_scenario: 'Szukaj w scenariuszach',
    table_search_desc: 'Szukaj w opisach',
    table_search_name: 'Szukaj w nazwach',
    table_search_email: 'Szukaj w emailach',
    clear: 'Wyczyść',
    save: 'Zapisz',
    quick_guide: 'Rozpocznij',
    instruction: 'Instrukcja',

    edit_profile: 'Edytuj profil',
    new_user: 'Nowy użytkownik',
    new_machine: 'Nowy opis maszyny',
    new_quiz: 'Nowy test',
    new_scenario: 'Nowy scenariusz',

    // user
    user_details: 'Szczegóły użytkownika',
    registration_date: 'Data rejestracji',
    delete_user_question: 'Czy na pewno chcesz trwale usunąć użytkownika? Tej operacji nie da się odwrócić.',
    deactivate_user_question: 'Czy na pewno chcesz deaktywować użytkownika? Ponowna aktywacja użytkownika wymaga edycji bazy danych.',

    // Vpn
    new_vpn: 'Nowy VPN',
    new_vpn_description: 'Nowy opis VPN',
    vpn_download_err: 'Wystąpił błąd podczas próby pobrania VPN',
    delete_vpn_question: 'Czy na pewno chcesz usunąć ten vpn?',
    delete_vpn_description_question: 'Czy na pewno chcesz usunąć ten opis vpn?',

    // challenge
    new_challenge: 'Nowe zadanie',
    delete_challenge_question: 'Czy na pewno chcesz usunąć to zadanie?',
    select_file: 'Wybierz plik',
    challenge_level: 'Poziom zadania',
    publication_date: 'Publikuj o',
    publication_time: 'Czas publikacji',
    solve_time_limit2: 'Ogranicz. czas rozw.',
    delayed_publication: 'Niestandardowy czas publikacji',
    set_active_to_enable_publication: 'Zaznacz opcję "Aktywne" inaczej publikacja zadania nie zadziała',
    not_active_challenges_not_in_ranking: 'Nieaktywne zadania nie liczą się do rankingu',
    publication_on_competition_start: 'Zadanie zostanie opublikowane ze startem konkursu',
    solve_time_limit: 'Ogranicz czas rozwiązywania',
    solve_end_date: 'Ogranicz czas rozwiązywania do',
    challenge_can_be_viewed_but_without_points: 'Zadania po terminie zgłoszeń są widoczne, ale nie można uzyskać za nie punktów',
    cannot_active_challenge_without_flags: 'Nie można aktywować zadania bez dodanych flag',
    cannot_active_challenge_without_flag: 'Nie można aktywować zadania bez dodanej flagi',
    cannot_active_challenge_with_0_points_flags: 'Nie można aktywować zadania jeśli którakolwiek z flag ma 0 punktów',
    solve_time_limit_cannot_be_lesser_than_publication_date: 'Data ograniczenia rozwiązywania zadań nie może być wcześniejsza niż data publikacji zadania',

    new_challenge_category: 'Nowa kategoria zadań',
    delete_challenge_category_question: 'Czy na pewno chcesz usunąć tę kategorię zadań?',

    // flags
    new_flag: 'Nowa flaga',
    delete_flag_question: 'Czy na pewno chcesz usunąć tę flagę?',

    user: 'Użytkownik',
    category: 'Kategoria',
    component: 'Komponent',
    ip_addr: 'Adres IP',
    verbosity_level: 'Poziom informacyjny',
    create_new_stack: 'Stwórz nowy stack',
    switch_stack: 'Zmień środowisko',
    switching_stack: 'Przełączanie na środowisko',
    stack_switch_question: 'Czy na pewno chcesz przełączyć środowisko?',
    stack_switch_poweroff: 'Wyłącz wszystkie maszyny z bieżącego środowiska',
    stack_switch_suspend: 'Uśpij wszystkie maszyny z bieżącego środowiska',
    stack: 'Stack',
    stacks: 'Stacki',
    stack_type: 'Typ stacka',
    stack_types: 'Typy stacka',
    default_stack: 'Domyślny stack',
    stack_unavailable: 'niedostępne',
    stack_suspend_question: 'Czy na pewno chcesz uśpić to środowisko?',
    stack_poweron_question: 'Czy na pewno chcesz włączyć to środwisko?',
    'stack_revert-with-poweron_question': 'Czy na pewno chcesz przywrócić i włączyć środowisko? UWAGA: wszystkie maszyny zostaną przywrócone do oryginalnego stanu.',
    stack_revert_question: 'Czy na pewno chcesz przywrócić środowisko? UWAGA: wszystkie maszyny zostaną przywrócone do oryginalnego stanu.',
    stack_disable_steerings: 'Wyłącz sterowanie stackiem',
    ctf_end_date_cannot_be_lesser_or_equal_than_start_date: 'Data zakończenia nie może być wcześniejsza ani równa dacie rozpoczęcia',

    current_stack: 'Bieżące środowisko',
    new_stack: 'Nowy stack',
    demo: 'Demo',
    is_ctf: 'CTF',
    ctf_time_limit: 'Ogranicz czas trwania',
    ctf_start_date: 'Data rozpoczęcia',
    ctf_end_date: 'Data zakończenia',
    freeze_scoreboard: 'Zamroź ranking',
    freeze_scoreboard_date: 'Data zamrożenia',
    clear_flags: 'Usuń przesłane flagi',
    scoreboard_is_frozen: 'Ranking został zamrożony',
    stats_top_x_teams: 'Statystyki dla TOP _COUNT_ zespołów',
    ctf_type: 'Typ CTF',
    select_existing_stack: 'Użyj istniejącego stacka',
    stack_name: 'Nazwa',
    status: 'Status',
    nr: 'Nr',
    used_by_users_count: 'Ilość użytkowników',
    used_by_users: 'Wykorzystywany przez użytkowników',
    vmware_user: 'Nazwa użytkownika VMware',
    vmware_password: 'Hasło użytkownika VMware',
    vmware_admin_user: 'Nazwa użytkownika admin. VMware',
    vmware_admin_password: 'Hasło użytkownika admin. VMware',
    use_overlord: 'Użyj Overlorda',
    overlord_connection_unavailable: 'Połączenie do Overlorda jest aktualnie niedostępne',
    delete_stack_question: 'Czy na pewno chcesz usunąć ten stack?',
    unfreeze_ranking_stack_question: 'Czy na pewno chcesz odmrozić ranking dla tego stacka? Automatyczne zamrożenie jeśli jest skonfigurowane zostanie wyłączone',
    freeze_ranking_stack_question: 'Czy na pewno chcesz zamrozić ranking dla tego stacka?',

    new_stack_description: 'Nowy opis stacka',
    stack_type_description: 'Opis typu stacka',
    ca_certificates: 'Certyfikaty CA',
    delete_stack_description_question: 'Czy na pewno chcesz usunąć ten opis stacka?',

    // users roles
    role_user: 'Użytkownik',
    role_user_recruitment: 'Użytkownik rekrutacyjny',
    role_user_ctf_ro: 'Użytkownik CTF tylko do odczytu',
    role_user_ranking_only: 'Użytkownik tylko z rankingiem',
    role_admin: 'Admin',

    // Menu
    menu_main_page: 'Strona główna',
    menu_scenarios: 'Scenariusze',
    menu_machines: 'Maszyny',
    menu_ranking: 'Ranking',
    menu_quizzes: 'Testy',
    menu_settings: 'Ustawienia',
    menu_users: 'Użytkownicy',
    menu_sessions: 'Sesje',
    menu_logs: 'Logi',
    menu_vpns: 'VPNy',
    menu_vpn_descriptions: 'Opisy VPNów',
    menu_stack_descriptions: 'Opisy stacków',
    menu_map_objects: 'Obiekty mapy',
    menu_stacks: 'Stacki',
    menu_maps: 'Mapy',
    menu_challenges: 'Zadania',
    menu_challenge_categories: 'Kat. zadań',
    menu_flags: 'Flagi',

    // map
    new_map_object: 'Nowy obiekt mapy',
    map_id: 'Identyfikator na mapie',
    map_legend: 'Legenda mapy',
    map_legend_pl: 'Polska legenda mapy',
    map_legend_en: 'Angielska legenda mapy',
    delete_map_object_question: 'Czy na pewno chcesz usunąć ten obiekt mapy?',
    remove_all_ctf_flags: 'Usuń wszystkie przesłane flagi CTF',

    // ctf
    paste_ctf_flag: 'Wklej flagę CTF...',
    ctf_start_in: 'CTF rozpocznie się za',
    ctf_end_in: 'Do końca CTF',
    ctf_started: 'Zawody rozpoczęte!',
    ctf_ended: 'Zawody zakończone!',

    map_label: 'Tytuł',
    map_icon: 'Ikonka',
    map_line_style: 'Styl linii',
    map_x: 'Pozycja X',
    map_y: 'Pozycja Y',
    map_height: 'Wysokość',
    map_width: 'Szerokość',
    map_type: 'Typ elementu',
    map_roundness: 'Wielkość łuku',
    map_title: 'Tytuł',
    map_subtitle: 'Podtytuł',
    map_subtitle2: 'Podtytuł 2',
    map_network_edge_position: 'Pozycja połączenia',
    map_ip: 'IP',
    map_ip2: 'IP2',
    map_network: 'Sieć dla IP',
    map_network2: 'Sieć dla IP2',
    map_ip_style: 'Styl dla IP',
    map_ip2_style: 'Styl dla IP2',
    map_ip_public: 'IP Publiczne',
    map_network_public: 'Sieć dla IP Publiczne',
    map_domain: 'Domena',
    map_dns: 'Nazwa DNS',
    map_dns_public: 'Nazwa DNS Publiczna',
    map_sublabel: 'Podtytuł',
    map_add_node: 'Dodaj element',
    map_add_edge: 'Dodaj połączenie',
    map_add_description: 'Kliknij puste miejsce aby dodać nowy element',
    map_add_edge_description: 'Kliknij element i przeciągnij na drugi element aby je połączyć',
    map_node_already_exists: 'Element o takim samym id już istnieje',
    attributes: 'Atrybuty',
    no_attributes: 'Brak atrybutów',
    delete_attribute_question: 'Czy na pewno chcesz usunąć ten atrybut?',
    map_x_offset: 'Przesunięcie X',
    delete_element: 'Usuń element',
    map_color: 'Kolor',
    map_position: 'Pozycja',
    map_cannot_unselect_element: 'Formularz zawiera błędy, nie można odznaczyć elementu',
    map_select_element_to_edit: 'Wybierz element na mapie aby edytować jego właściwości',

    // Popup
    success: 'Sukces',
    oops: 'Ups',
    // Quiz popup
    _question: 'Pytanie',
    _question_pl: 'Pytanie pl',
    _question_en: 'Pytanie en',

    _question_name_pl: 'Nazwa pytania PL',
    _question_name_en: 'Nazwa pytania EN',
    _question_desc_pl: 'Opis pytania PL',
    _question_desc_en: 'Opis pytania EN',
    solved: 'Rozwiązane',
    flag: 'Flaga',
    input_flag_please: 'Wpisz flagę w pole!',
    input_answer_please: 'Wpisz rozwiązanie w pole!',
    send: 'Wyślij',
    incorrect_attempts: 'Błędnych prób',
    answered_first_time: 'Odpowiedź poprawna za pierwszym razem',
    flag_unique_per_user: 'Flaga unikalna dla użytkowników',
    flags_for_users: 'Flagi dla użytkowników',
    no_flags: 'Brak flag',
    flag_details: 'Szczegóły flagi',
    flag_case_insensitive: 'Flaga ma być "case insensitive"',

    _answers: 'Odpowiedzi',
    answer: 'Odpowiedź',
    all_done: 'Zakończono',
    close: 'Zamknij',
    next: 'Następne',
    // Ranking
    name: 'Imię',
    score: 'Punkty',
    score_for_quiz: 'Przyznane punkty za rozwiązanie',
    badges: 'Odznaki',
    place: 'Miejsce',
    edit: 'Edytuj',
    delete: 'Usuń',
    deactivate: 'Deaktywuj',
    deleted: 'usunięty',
    done: 'Zrobione',
    suspend: 'Uśpij',
    suspend_stack: 'Uśpij środowisko',
    refresh: 'Odśwież',
    poweron: 'Włącz',
    wakeup_stack: 'Wzbudź środowisko',
    stack_changed: 'Środowisko zostało zmienione',
    stack_changed_info: 'W innej zakładce przeglądarki zostało zmienione środowisko, prezentowane dane nie są aktualne, czy chcesz teraz odświeżyć aplikację? Nie odświeżenie aplikacji będzie powodowało błędne działanie środowiska.',
    stack_changed_vm_disconnected: 'Ze względu na zmianę środowiska konsola maszyny została rozłączona. Aby ponownie uzyskać dostęp do konsoli tej maszyny wróć do poprzedniego środowiska i otwórz konsolę jeszcze raz.',
    console_session_expired: 'Sesja wygasła, zaloguj się ponownie i otwórz konsolę do tej maszyny jeszcze raz.',

    detailed_table: 'Szczegółowa tabela',
    ranking_progress: 'Wykres i tabela postępów',

    // Pytania przy podejmowaniu akcji
    short_name: 'PL',
    long_name: 'Polski',
    confirm: 'Tak',
    cancel: 'Anuluj',
    submit: 'Zatwierdź',
    question: 'Jesteś pewien?',
    machine_reboot_question: 'Czy na pewno chcesz zresetować maszynę?',
    machine_revert_question: 'Czy na pewno chcesz przywrócić maszynę?',
    machine_power_question: 'Czy na pewno chcesz wyłączyć maszynę?',

    scenario_revert_question: 'Czy na pewno chcesz zrewertować scenariusz?',
    scenario_test_question: 'Czy na pewno chcesz testować scenariusz?',
    scenario_log_generate_question: 'Czy na pewno chcesz wygenerować logi?',

    quiz_start_question: 'Czy na pewno chcesz rozpocząć ten test?',

    machine_reboot_ok: 'Pomyślnie zrebootowano maszynę',
    machine_revert_ok: 'Pomyślnie zrewertowano maszynę',
    machine_on_ok: 'Pomyślnie włączono maszynę',
    machine_off_ok: 'Pomyślnie wyłączono maszynę',

    machine_reboot_err: 'Wystąpił błąd przy restartowaniu',
    machine_revert_err: 'Wystąpił błąd przy revertowaniu',
    machine_on_err: 'Wystąpił błąd przy włączaniu',
    machine_off_err: 'Wystąpił błąd przy wyłączaniu',
    machine_console_err: 'Wystąpił błąd przy włączaniu konsoli',
    machine_revert_all_question: 'Czy na pewno chcesz zrewertować wszystkie maszyny?',
    machine_setup_new_env_question: 'Czy na pewno chcesz przywrócić środowisko? UWAGA: wszystkie maszyny zostaną przywrócone do oryginalnego stanu.',
    // machine_setup_new_env_question: 'Czy na pewno chcesz przywrócić środowisko? UWAGA: wszystkie maszyny (wszystkie przypisane do Ciebie, nie tylko widoczne te poniżej) zostaną przywrócone do oryginalnego stanu.',
    machine_power_on_all_question: 'Czy na pewno chcesz włączyć wszystkie maszyny?',
    machine_power_off_all_question: 'Czy na pewno chcesz wyłączyć wszystkie maszyny?',
    // "scenario_revert_ok": "Pomyślnie zrewertowano scenariusz",
    // "scenario_test_ok": "Scenariusz pomyślnie przeszedł wszystkie testy",
    // "scenario_log_generate_ok": "Logi pomylnie wygenerowane",

    machine_revert_all_ok: 'Zrewertowano wszystkie maszyny',
    machine_revert_all_err: 'Zrewertowanie wszystkich maszyn nie udało się',

    machine_setup_new_env_ok: 'Przywrócono środowisko',
    machine_setup_new_env_err: 'Przywrócenie środowiska nie udało się',
    machine_power_on_all_ok: 'Włączono wszystkie maszyny',
    machine_power_on_all_err: 'Włączenie wszystkich maszyn nie udało się',
    machine_power_off_all_ok: 'Wyłączono wszystkie maszyny',
    machine_power_off_all_err: 'Wyłączenie wszystkich maszyn nie udało się',

    clear_all_ctf_flags_question: 'Czy na pewno chcesz usunąć wszystkie przesłane flagi CTF? UWAGA: utracone zostaną wszystkie flagi wszystkich użytkowników co spowoduje usunięcie rankingu CTF',
    clear_stack_ctf_flags_question: 'Czy na pewno chcesz usunąć wszystkie przesłane flagi CTF dla tego stacka? UWAGA: spowoduje to także usunięcie rankingu',

    scenario_on_ok: 'Pomyślnie włączono scenariusz',
    scenario_off_ok: 'Pomyślnie wyłączono scenariusz',
    // Alert powiadomień
    status_poweredOn: 'On',
    status_poweredOff: 'Off',
    status_err: 'Błąd',
    status_waiting: 'Oczekuje',
    status_turning_on: 'Włączanie',
    status_turning_off: 'Wyłączanie',
    status_rebooting: 'Resetowanie',
    status_reverting: 'Przywracanie',
    status_logging: 'Generowanie logów',
    status_testing: 'Testowanie',
    status_suspended: 'Uśpiona',
    status_suspending: 'Usypianie',
    stack_status_poweringon: 'włączanie',
    stack_status_poweredon: 'włączone',
    stack_status_poweredoff: 'wyłączone',
    stack_status_poweringoff: 'wyłączanie',
    stack_status_rebooting: 'resetowanie',
    stack_status_reverting: 'przywracanie',
    stack_status_testing: 'testowanie',
    stack_status_suspended: 'uśpione',
    stack_status_suspending: 'usypianie',
    stack_status_failed: 'uszkodzony',
    stack_status_failed_explain: 'Wystąpił nieoczekiwany błąd, środowisko nie jest w pełni funkcjonalne. Skontaktuj się z administratorem lub spróbuj przywrócić środowisko jeszcze raz.',
    'stack_status_waiting-for-create': 'oczekuje na utworzenie',
    'stack_status_waiting-for-poweron': 'oczekuje na włączenie',
    'stack_status_waiting-for-poweroff': 'oczekuje na wyłączenie',
    'stack_status_waiting-for-suspend': 'oczekuje na uśpienie',
    'stack_status_waiting-for-delete': 'oczekuje na usunięcie',
    'stack_status_waiting-for-test': 'oczekuje na test',
    'stack_status_waiting-for-revert': 'oczekuje na przywrócenie',

    // Nazwy guzików
    no_file: 'Brak w pl',
    download: 'Ściągnij',
    materials: 'Materiały',
    premium_version_only: 'Dostępne w płatnej wersji',
    revert: 'Przywróć',
    revert_and_poweroff_stack: 'Przywróć i wyłącz środowisko',
    revert_and_poweron_stack: 'Przywróć i włącz środowisko',
    revert_and_poweroff_machines: 'Przywróć i wyłącz maszyny',
    revert_all_machines: 'Przywróć wszystkie maszyny',
    setup_new_env: 'Przywróć środowisko',
    power_on_all_machines: 'Włącz wszystkie',
    power_off_all_machines: 'Wyłącz wszystkie',
    log_generate: 'Generuj logi',
    test: 'Testuj',
    reboot: 'Resetuj',
    return: 'Powrót',
    console: 'Konsola',
    ctrl_alt_del: 'Ctrl + Alt + Del',
    fullscreen: 'Tryb pełnoekranowy',
    paste_from_clipboard: 'Wklej ze schowka',
    popup: 'Okno',
    new_window: 'Nowa karta',
    console_is_disabled: 'Konsola jest wyłączona',
    reboot_is_disabled: 'Resetowanie jest wyłączone',
    revert_is_disabled: 'Revert jest wyłączony',
    power_is_disabled: 'Zarządzanie stanem jest wyłączone',
    power_is_disabled_stack_turned_off: 'Zarządzanie stanem jest zablokowane dlatego, że całe środowisko jest wyłączone',
    revert_is_disabled_stack_turned_off: 'Przywracanie jest zablokowane dlatego, że całe środowisko jest wyłączone',
    reboot_is_disabled_stack_turned_off: 'Resetowanie jest zablokowane dlatego, że całe środowisko jest wyłączone',

    // Alerty o braku nazwy
    scenario_no_name: 'Brak nazwy PL',
    scenario_no_desc: 'Brak opisu PL',
    scenario_no_desc_long: 'Brak opisu PL',
    machine_no_name: 'Brak nazwy PL',
    machine_no_desc: 'Brak opisu PL',
    machine_name_not_available: 'Maszyna niedostępna',
    machine_desc_not_available: 'Maszyna jest aktualnie niedostępna',
    element_name_not_available_demo: 'Element niedostępny w wersji demo',
    element_desc_not_available_demo: 'Element nie jest dostępny w wersji demo',
    delete_machine_question: 'Czy na pewno chcesz usunąć tę maszynę?',
    delete_scenario_question: 'Czy na pewno chcesz usunąć ten scenariusz?',
    machine_not_available_in_stack: 'Maszyna niedostępna w żadnym stacku o wybranym typie',

    quiz_scenario_no_name_specified: 'Należy wybrać scenariusz!',
    quiz_scenario_no_desc_pl: 'Należy wpisać polski opis!',
    quiz_no_seconds: 'Należy wpisać liczbę sekund!',
    quiz_no_score: 'Należy wpisać wynik za rozwiązanie!',
    quiz_at_least_1_question: 'Liczba musi być większa od 1',
    number_of_questions: 'Liczba pytań',
    display_name: 'Nazwa wyświetlana',
    delete_quiz_question: 'Czy na pewno chcesz usunąć ten test?',

    quiz_desc_pl: 'Długi polski opis',
    quiz_desc_en: 'Długi angielski opis',
    quiz_desc_short_pl: 'Krótki polski opis',
    quiz_desc_short_en: 'Krótki angielski opis',
    quiz_display_name_pl: 'Polska nazwa wyświetlana',
    quiz_display_name_en: 'Angielska nazwa wyświetlana',
    quiz_time_seconds: 'Czas na wykonanie',
    seconds: 'Sekundy',

    user_quiz_details: 'Szczegóły testu',
    quizzes_in_progress: 'Testy w trakcie rozwiązywania',
    quizzes_in_progress_empty: 'Brak rozpoczętych testów',
    quizzes_solved: 'Testy rozwiązane',
    quizzes_solved_empty: 'Brak rozwiązanych testów',
    quiz_started_at: 'Data rozpoczęcia',
    quiz_solved_at: 'Data ukończenia',
    challenges_solved: 'Ukończone zadania',
    challenges_solved_empty: 'Brak rozwiązanych zadań',
    points: 'punktów',

    // Common
    tags: 'Tagi',
    scenario_usage: 'Zużycie zasobów',
    scenario_machines_status: 'Status maszyn',
    search: 'Szukaj',
    certificates: 'Certyfikaty',

    // vpns
    vpns: 'VPNy',
    download_conf: 'Pobierz konfigurację',

    // Login and register
    login: 'Logowanie',
    registration: 'Rejestracja',
    register_now: 'Zarejestruj się!',
    reset_password_link: 'Resetuj hasło',
    reset_password: 'Resetowanie hasła',
    back_to_login: 'Wróć do strony logowania',
    reset_password_action: 'Resetuj hasło',
    enter_email_used_during_registration: 'Podaj email użyty podczas rejestracji',
    set_new_password: 'Ustaw nowe hasło',

    register: 'Zarejestruj',
    log_in: 'Zaloguj',
    logout: 'Wyloguj',
    profile: 'Profil',

    login_name: 'Użytkownik',
    login_email: 'E-mail',
    login_password: 'Hasło',
    login_password_repeat: 'Powtórz hasło',
    login_current_password: 'Obecne hasło',
    login_new_password: 'Nowe hasło',

    // Validators
    login_name_err: 'Musisz wpisać nazwę użytkownika!',
    login_email_err: 'Musisz wpisać adres e-mail!',
    login_password_err: 'Musisz wpisać hasło!',
    login_password_repeat_err: 'Musisz wpisać ponownie hasło!',
    login_password_repeat_no_match_err: 'Hasła się nie zgadzają!',

    // logs
    log_details: 'Szczegóły logu',
    delete_log_question: 'Czy na pewno chcesz usunąć ten log',

    // sessions
    sessions_iat: 'Data zalogowania',
    sessions_exp: 'Data wygaśnięcia',

    // scenarios blocking
    reservation_cancel: 'Anuluj rezerwację',
    book_1_day: 'Rezerwuj na 1 dzień',
    book_2_day: 'Rezerwuj na 2 dni',
    book_3_day: 'Rezerwuj na 3 dni',
    extend_1_day: 'Przedłuż o 1 dzień',
    extend_2_day: 'Przedłuż o 2 dni',
    extend_3_day: 'Przedłuż o 3 dni'
  },
  en: {
    // Table
    scenario: 'Scenario',
    description: 'Description',
    actions: 'Actions',
    name_name: 'Name',
    role: 'Role',
    difficulty: 'Difficulty',
    machines: 'Machines',
    machine_id: 'Machine UUID',
    answers_split_by_comma: 'Answers separated by comma',
    back: 'Back',
    time: 'Time',
    time_hms: 'Time (HH:MM:SS)',
    data_length: 'Number of questions',
    type: 'Type',
    order: 'Order',
    scenario_type_presentation: 'Presentation',
    scenario_type_education: 'Educational',
    scenario_type_recruit: 'Recruiting',
    scenario_type_hardware: 'Hardware',
    scenario_type_blueteam: 'Blue team',
    machine_name: 'Machine name',
    network_name: 'Network name',
    event_date: 'Event date',
    date: 'Date',
    table_sort_desc: 'Sort descending',
    table_sort_asc: 'Sort ascending',
    table_sort_cancel: 'Click to cancel sorting',
    table_pagination_page: '/ page',
    permission_error: 'No permissions',
    not_found_error: 'Page not found',

    congrats: 'Congratulations!',
    congrats2: 'MISSION COMPLETED, You did it!',
    all_flags_done: 'All flags done :-)',
    solve_more: 'Although, remember that score matters at the end. ;-)',
    submit_flag: 'Submit flag',
    time_to_solve_expired: 'Time to send the flag is over, if you got the flag, you can check its correctness but it will not be included in the ranking.',
    solve_time_will_expire_at: 'The solve submission for this task is limited to',
    solves: 'Solves',
    solves2: 'Solves',
    no_solves: 'No solves found',
    attached_flags: 'Attached flags',
    value: 'Value',
    attached_challenge: 'Attached challenge',
    challenge_details: 'Challenge details',
    user_points: 'Your points',

    // universal
    click_to_copy: 'Click to copy',
    copied_to_clipboard: 'Copied to clipboard',
    more_info: 'More info',
    current: 'current',
    files: 'Files',
    file: 'File',
    remove_real_file_question: 'Are you sure to delete this file? This operation is immediate and irreversible.',
    send_file: 'Send file',

    // Kreator scenariuszy
    field_required: '* Field required',
    name_pl: 'Polish name',
    name_en: 'English name',
    desc_pl: 'Polish description',
    desc_en: 'English description',
    desc_long_pl: 'Long desc pl',
    desc_long_en: 'Long desc en',
    number_of_files: 'File number',
    file_label_pl: 'Polish file title',
    file_label_en: 'English file title',
    file_type: 'File type',
    file_path_pl: 'Polish filepath',
    file_path_en: 'English filepath',
    file_pl: 'Polish file',
    file_en: 'English file',
    file_in_dir: 'file in directory',
    file_path_placeholder: 'eg.: %LANG%_filename.pdf',
    active: 'Active',
    active2: 'Active',
    active3: 'Active',
    inactive: 'inactive',
    ctf_start: 'CTF start',
    added_to_map: 'Added to map',
    s_type: 'Scenario type',
    show: 'Show',
    view_for_roles: 'Visible for roles',
    view_for_stack_types: 'Visible for stack types',
    unfreeze_ranking: 'Unfreeze ranking',
    recruitment_scenario: 'Recruitment scenario',
    scenario_demo: 'Demo version (no access to materials)',
    switch_to_this_stack: 'Switch to this stack',
    your_current_stack: 'Your current stack',
    scenario_reservable: 'Reservable by user',

    reserved_to: 'Reserved to',
    reserved_by: 'by',
    active_reservations: 'Active reservations',

    // search
    table_search_scenario: 'Search in scenario',
    table_search_desc: 'Search descriptions',
    table_search_name: 'Search in names',
    table_search_email: 'Search in emails',
    clear: 'Clear',
    save: 'Save',
    quick_guide: 'Quick guide',
    instruction: 'Instruction',

    edit_profile: 'Edit profile',
    new_user: 'New user',
    new_machine: 'New machine description',
    new_quiz: 'New quiz',
    new_scenario: 'New scenario',

    // user
    user_details: 'Details of user',
    registration_date: 'Registration date',
    delete_user_question: 'Are you sure to delete this user permanently?',
    deactivate_user_question: 'Are you sure to deactivate this user? Reactivating a user requires editing the database.',

    // Vpn
    new_vpn: 'New VPN',
    new_vpn_description: 'New VPN description',
    vpn_download_err: 'There was a problem downloading VPN',
    delete_vpn_question: 'Are you sure to delete this vpn?',
    delete_vpn_description_question: 'Are you sure to delete this vpn description?',

    // challenge
    new_challenge: 'New challenge',
    delete_challenge_question: 'Are you sure to delete this challenge?',
    select_file: 'Select file',
    challenge_level: 'Challenge level',
    publication_date: 'Publish at',
    delayed_publication: 'Non-standard publication time',
    set_active_to_enable_publication: 'Check "Active" option otherwise challenge will not be published',
    not_active_challenges_not_in_ranking: 'Not active challenges are not included in ranking',
    publication_on_competition_start: 'Challenge will be published with the start of the competition',
    solve_time_limit: 'Limit the solving time',
    solve_end_date: 'Limit the time of solving to',
    challenge_can_be_viewed_but_without_points: 'Challenges after the solve date are visible but users cannot submit flags to it',
    cannot_active_challenge_without_flags: 'Cannot activate challenge without selecting flags',
    cannot_active_challenge_without_flag: 'Cannot activate challenge without selecting flag',
    cannot_active_challenge_with_0_points_flags: 'Cannot activate challenge with flags with zero points',
    solve_time_limit_cannot_be_lesser_than_publication_date: 'The date of limitation of solving challenges cannot be earlier than the date of publication of the challenge',

    new_challenge_category: 'New challenge category',
    delete_challenge_category_question: 'Are you sure to delete this challenge category?',

    // flags
    new_flag: 'New flag',
    delete_flag_question: 'Are you sure to delete this flag?',

    user: 'User',
    category: 'Category',
    component: 'Component',
    ip_addr: 'IP address',
    verbosity_level: 'Verbosity',
    create_new_stack: 'Create new stack',
    switch_stack: 'Change environment',
    switching_stack: 'Changing environment to',
    stack_switch_question: 'Are you sure to switch environment?',
    stack_switch_poweroff: 'Power off all machines from current environment',
    stack_switch_suspend: 'Suspend all machines from current environment',
    stack: 'Stack',
    stacks: 'Stacks',
    stack_type: 'Stack type',
    stack_types: 'Stack types',
    default_stack: 'Default stack',
    stack_unavailable: 'unavailable',
    stack_suspend_question: 'Are you sure to suspend this environment?',
    stack_poweron_question: 'Are you sure to power on this environment?',
    'stack_revert-with-poweron_question': 'Do you want to revert and power on environment? WARNING: All machines will be restored to original state.',
    stack_revert_question: 'Do you want to revert environment? WARNING: All machines will be restored to original state.',
    stack_disable_steerings: 'Disable stack steerings',
    ctf_end_date_cannot_be_lesser_or_equal_than_start_date: 'The end date cannot be earlier than or equal to the start date',

    current_stack: 'Current environment',
    new_stack: 'New stack',
    demo: 'Demo',
    is_ctf: 'CTF',
    ctf_time_limit: 'Limit the duration',
    ctf_start_date: 'Start date',
    ctf_end_date: 'Finish date',
    freeze_scoreboard: 'Freeze scoreboard',
    freeze_scoreboard_date: 'Date of freezing',
    clear_flags: 'Remove submited flags',
    scoreboard_is_frozen: 'Scoreboard has been frozen',
    stats_top_x_teams: 'Statistics are for TOP _COUNT_ teams',
    ctf_type: 'CTF type',
    select_existing_stack: 'Use existing stack',
    stack_name: 'Name',
    status: 'Status',
    nr: 'Nr',
    used_by_users_count: 'Users count',
    used_by_users: 'Used by users',
    vmware_user: 'VMware username',
    vmware_password: 'VMware password',
    vmware_admin_user: 'VMware admin username',
    vmware_admin_password: 'VMware admin password',
    use_overlord: 'Use Overlord',
    overlord_connection_unavailable: 'Connection to Overlord is currently unavailable',
    delete_stack_question: 'Are you sure to delete this stack?',
    unfreeze_ranking_stack_question: 'Are you sure to unfreeze ranking for this stack? Automatic freeze will be disabled if is configured',
    freeze_ranking_stack_question: 'Are you sure to freeze ranking for this stack?',

    new_stack_description: 'New stack description',
    stack_type_description: 'Description of stack type',
    ca_certificates: 'CA certificates',
    delete_stack_description_question: 'Are you sure to delete this stack description?',

    // users roles
    role_user: 'User',
    role_user_recruitment: 'Recruitment user',
    role_user_ctf_ro: 'User CTF view only',
    role_user_ranking_only: 'User ranking view only',
    role_admin: 'Admin',

    // Menu
    menu_main_page: 'Home page',
    menu_scenarios: 'Scenarios',
    menu_machines: 'Machines',
    menu_ranking: 'Ranking',
    menu_quizzes: 'Tests',
    menu_settings: 'Settings',
    menu_users: 'Users',
    menu_sessions: 'Sessions',
    menu_logs: 'Logs',
    menu_vpns: 'VPNs',
    menu_vpn_descriptions: 'VPN descript.',
    menu_stack_descriptions: 'Stack desc.',
    menu_map_objects: 'Map objects',
    menu_stacks: 'Stacks',
    menu_maps: 'Maps',
    menu_challenges: 'Challenges',
    menu_challenge_categories: 'Chall. cat.',
    menu_flags: 'Flags',

    // map
    new_map_object: 'New map object',
    map_id: 'Identifier on map',
    map_legend: 'Map legend',
    map_legend_pl: 'Polish map legend',
    map_legend_en: 'English map legend',
    delete_map_object_question: 'Are you sure to delete this map object?',
    remove_all_ctf_flags: 'Remove all submited CTF Flags',

    // ctf
    paste_ctf_flag: 'Paste CTF flag...',
    ctf_start_in: 'CTF starts in',
    ctf_end_in: 'CTF ends in',
    ctf_started: 'CTF started!',
    ctf_ended: 'CTF is ended!',

    // map editor
    map_label: 'Label',
    map_icon: 'Icon',
    map_line_style: 'Line style',
    map_x: 'Position X',
    map_y: 'Position Y',
    map_height: 'Height',
    map_width: 'Width',
    map_type: 'Element type',
    map_roundness: 'Roundness',
    map_title: 'Title',
    map_subtitle: 'Subtitle',
    map_subtitle2: 'Subtitle 2',
    map_network_edge_position: 'Position of connection',
    map_ip: 'IP',
    map_ip2: 'IP2',
    map_network: 'Network for IP',
    map_network2: 'Network for IP2',
    map_ip_style: 'Style of IP',
    map_ip2_style: 'Style of IP2',
    map_ip_public: 'IP Public',
    map_network_public: 'Network for IP Public',
    map_domain: 'Domain',
    map_dns: 'DNS name',
    map_dns_public: 'DNS Public name',
    map_sublabel: 'Sublabel',
    map_add_node: 'Add element',
    map_add_edge: 'Add connection',
    map_add_description: 'Click in an empty space to place a new element',
    map_add_edge_description: 'Click on a element and drag the edge to another element to connect them',
    map_node_already_exists: 'Element with the same id already exists',
    attributes: 'Attributes',
    no_attributes: 'No attributes found',
    delete_attribute_question: 'Are you sure to delete this attribute?',
    map_x_offset: 'Offset X',
    delete_element: 'Delete element',
    map_color: 'Color',
    map_position: 'Position',
    map_cannot_unselect_element: 'Form contains errors, cannot unselect element',
    map_select_element_to_edit: 'Select an element on the map to edit its properties',

    success: 'Success',
    oops: 'Oops',
    _question: 'Question',
    _question_pl: 'Question PL',
    _question_en: 'Question EN',
    _question_name_pl: 'Question name PL',
    _question_name_en: 'Question name EN',
    _question_desc_pl: 'Question desc PL',
    _question_desc_en: 'Question desc EN',
    solved: 'Solved',
    flag: 'Flag',
    input_flag_please: 'Input flag please',
    input_answer_please: 'Input your answer!',
    send: 'Send',
    incorrect_attempts: 'Incorrect attempts',
    answered_first_time: 'Answered properly at first attempt',
    flag_unique_per_user: 'Flag is unique values for users',
    flags_for_users: 'Flags for users',
    no_flags: 'No flags found',
    flag_details: 'Flag details',
    flag_case_insensitive: 'Flag must be case-insensitive',

    _answers: 'Answers',
    answer: 'Answer',
    all_done: 'Finished',
    close: 'Close',
    next: 'Next',
    // Ranking
    name: 'Name',
    score: 'Score',
    score_for_quiz: 'Score for test',
    badges: 'Badges',
    place: 'Place',
    edit: 'Edit',
    delete: 'Delete',
    deactivate: 'Deactivate',
    deleted: 'deleted',
    done: 'Done',
    suspend: 'Suspend',
    suspend_stack: 'Suspend environment',
    refresh: 'Refresh',
    poweron: 'Power on',
    wakeup_stack: 'Wake up environment',
    stack_changed: 'Environment changed',
    stack_changed_info: 'The environment has been changed in another browser tab, the presented data is not up-to-date, do you want to refresh the application now? Not refreshing the application may cause malfunction of the environment.',
    stack_changed_vm_disconnected: 'Due to the change of environment, the machine console has become disconnected. To access the console of this machine again, go back to the previous environment and open the console again.',
    console_session_expired: 'Session expired, sign in again and open console to this machine one more time.',

    detailed_table: 'Detailed table',
    ranking_progress: 'Progress chart and table',

    // Actions
    short_name: 'EN',
    long_name: 'English',
    confirm: 'Yes',
    cancel: 'Cancel',
    submit: 'Submit',
    question: 'Are you sure?',
    machine_reboot_question: 'Do you want to reboot this machine?',
    machine_revert_question: 'Do you want to revert this machine?',
    machine_power_question: 'Do you want to power off this machine?',

    scenario_revert_question: 'Do you want to revert this scenario?',
    scenario_test_question: 'Do you want to test this scenario?',
    scenario_log_generate_question: 'Do you want to generate logs?',
    machine_revert_all_question: 'Do you want to revert all machines?',
    machine_setup_new_env_question: 'Do you want to revert environment? WARNING: All machines will be restored to original state.',
    // machine_setup_new_env_question: 'Do you want to revert environment? WARNING: All machines (all assigned to you, not only visible below) will be restored to original state.',
    machine_power_on_all_question: 'Do you want to power on all machines?',
    machine_power_off_all_question: 'Do you want to power off all machines?',

    quiz_start_question: 'Do you want to start this test?',

    machine_reboot_ok: 'Machine has been rebooted',
    machine_revert_ok: 'Machine has been reverted',
    machine_on_ok: 'Machine has been turned on',
    machine_off_ok: 'Machine has been turned off',

    machine_reboot_err: 'There was a problem rebooting the machine',
    machine_revert_err: 'There was a problem reverting the machine',
    machine_on_err: 'There was a problem turning on the machine',
    machine_off_err: 'There was a problem turning off the machine',
    machine_console_err: 'There was a problem launching the console',

    scenario_revert_ok: 'Scenario has been reverted',
    scenario_test_ok: 'Tests for this scenario passed successfully',
    scenario_log_generate_ok: 'Scenario logs generated',
    machine_revert_all_ok: 'All machines has been reverted',
    machine_revert_all_err: 'Revert of all machines failed',
    machine_setup_new_env_ok: 'Environment has been reverted',
    machine_setup_new_env_err: 'Revert of environment failed',
    machine_power_on_all_ok: 'All machines has been powered on',
    machine_power_on_all_err: 'Power on of all machines failed',
    machine_power_off_all_ok: 'All machines has been powered off',
    machine_power_off_all_err: 'Power off of all machines failed',

    clear_all_ctf_flags_question: 'Do you want to remove all submited CTF flags? WARNING: all flags of all users will be lost, which will also remove the CTF ranking',
    clear_stack_ctf_flags_question: 'Do you want to remove all submited CTF flags for this stack? WARNING: the CTF ranking will be also removed',

    scenario_on_ok: 'Scenario turned on',
    scenario_off_ok: 'Scenario turned off',

    // Notifications
    status_poweredOn: 'On',
    status_poweredOff: 'Off',
    status_err: 'Error',
    status_waiting: 'Waiting',
    status_turning_on: 'Turning on',
    status_turning_off: 'Turning off',
    status_rebooting: 'Rebooting',
    status_reverting: 'Reverting',
    status_logging: 'Generating logs',
    status_testing: 'Testing',
    status_suspended: 'Suspended',
    status_suspending: 'Suspending',
    stack_status_poweringon: 'turning on',
    stack_status_poweredon: 'on',
    stack_status_poweredoff: 'off',
    stack_status_poweringoff: 'turning off',
    stack_status_rebooting: 'rebooting',
    stack_status_reverting: 'reverting',
    stack_status_testing: 'testing',
    stack_status_suspended: 'suspended',
    stack_status_suspending: 'suspending',
    stack_status_failed: 'broken',
    stack_status_failed_explain: 'An unexpected error has occurred, the environment is not fully functional. Please contact the administrator or try restoring the environment again.',
    'stack_status_waiting-for-create': 'waiting for create',
    'stack_status_waiting-for-poweron': 'waiting for power on',
    'stack_status_waiting-for-poweroff': 'waiting for power off',
    'stack_status_waiting-for-suspend': 'waiting for suspend',
    'stack_status_waiting-for-delete': 'waiting for delete',
    'stack_status_waiting-for-test': 'waiting for test',
    'stack_status_waiting-for-revert': 'waiting for revert',

    // Buttons
    no_file: 'Not avaliable',
    download: 'Download',
    materials: 'Materials',
    premium_version_only: 'Available in premium version',
    revert: 'Revert',
    revert_and_poweroff_stack: 'Revert and power off stack',
    revert_and_poweron_stack: 'Revert and power on stack',
    revert_and_poweroff_machines: 'Revert and power off machines',
    log_generate: 'Generate logs',
    test: 'Test',
    reboot: 'Reboot',
    return: 'Close',
    revert_all_machines: 'Revert all',
    setup_new_env: 'Revert environment',
    power_on_all_machines: 'Power on all',
    power_off_all_machines: 'Power off all',
    console: 'Console',
    ctrl_alt_del: 'Ctrl + Alt + Del',
    fullscreen: 'Fullscreen',
    paste_from_clipboard: 'Paste from clipboard',
    popup: 'Popup',
    new_window: 'New tab',
    console_is_disabled: 'Console is disabled',
    reboot_is_disabled: 'Reboot is disabled',
    revert_is_disabled: 'Revert is disabled',
    power_is_disabled: 'State management is disabled',
    power_is_disabled_stack_turned_off: 'State management is blocked because the environment is turned off',
    revert_is_disabled_stack_turned_off: 'Revert is blocked because the environment is turned off',
    reboot_is_disabled_stack_turned_off: 'Reboot is blocked because the environment is turned off',

    // Lack of names and descriptions
    scenario_no_name: 'No name in en',
    scenario_no_desc: 'No description in en',
    scenario_no_desc_long: 'No description in en',
    machine_no_name: 'No name in en',
    machine_no_desc: 'No description in en',
    machine_name_not_available: 'Machine unavailable',
    machine_desc_not_available: 'Machine is currently unavailable',
    element_name_not_available_demo: 'Element unavailable in demo version',
    element_desc_not_available_demo: 'Element is currently unavailable in demo version',
    delete_machine_question: 'Are you sure to delete this machine?',
    delete_scenario_question: 'Are you sure to delete this scenario?',
    machine_not_available_in_stack: 'Machine is not available in any stack of current type',

    quiz_scenario_no_name_specified: 'Choose scenario from the list!',
    quiz_scenario_no_desc_pl: 'Specify polish test description',
    quiz_no_seconds: 'Specify number of seconds!',
    quiz_no_score: 'Specify the score!',
    quiz_at_least_1_question: 'Must be greater than 1',
    number_of_questions: 'Questions number',
    display_name: 'Display name',
    delete_quiz_question: 'Are you sure to delete this quiz?',

    quiz_desc_pl: 'Polish long descripiton',
    quiz_desc_en: 'English long description',
    quiz_desc_short_pl: 'Polish short descripiton',
    quiz_desc_short_en: 'English short description',
    quiz_display_name_pl: 'Polish display name',
    quiz_display_name_en: 'English display name',
    quiz_time_seconds: 'Time for test',
    seconds: 'Seconds',

    user_quiz_details: 'Details of quiz',
    quizzes_in_progress: 'Quizzes in progress',
    quizzes_in_progress_empty: 'No quizzes in progress found',
    quizzes_solved: 'Solved quizzes',
    quizzes_solved_empty: 'No solved quizzes found',
    quiz_started_at: 'Start date',
    quiz_solved_at: 'Solve date',
    challenges_solved: 'Solved challenges',
    challenges_solved_empty: 'No solved challenges found',
    points: 'points',

    // Common
    scenario_usage: 'Resources usage',
    scenario_machines_status: 'Machines status',
    tags: 'Tags',
    search: 'Search',
    certificates: 'Certificates',

    // vpns
    vpns: 'VPNs',
    download_conf: 'Download configuration',

    // Login and register
    login: 'Login',
    registration: 'Registration',
    register_now: 'Register now!',
    reset_password_link: 'Reset password',
    reset_password: 'Reset password',
    back_to_login: 'Back to login page',
    reset_password_action: 'Reset password',
    enter_email_used_during_registration: 'Enter email used during registration',
    set_new_password: 'Set new password',

    register: 'Register',
    log_in: 'Log in',
    logout: 'Logout',
    profile: 'Profile',

    login_name: 'Name',
    login_email: 'E-mail',
    login_password: 'Password',
    login_password_repeat: 'Repeat password',
    login_current_password: 'Current password',
    login_new_password: 'New password',

    // Validators
    login_name_err: 'Please input your name!',
    login_email_err: 'Please input your e-mail address!',
    login_password_err: 'Please input your password!',
    login_password_repeat_err: 'Please input your password again!',
    login_password_repeat_no_match_err: "Passwords doesn't match!",

    // logs
    log_details: 'Log details',
    delete_log_question: 'Are you sure to delete this log?',

    // sessions
    sessions_iat: 'Issued at',
    sessions_exp: 'Expires',

    // scenarios blocking
    reservation_cancel: 'Cancel my booking',
    book_1_day: 'Book for 1 day',
    book_2_day: 'Book for 2 days',
    book_3_day: 'Book for 3 days',
    extend_1_day: 'Extend book for 1 day',
    extend_2_day: 'Extend book for 2 days',
    extend_3_day: 'Extend book for 3 days'
  },
  universal: {
    error: 'Error'
  }
}

export const pathNames = [
  {
    name: 'menu_main_page',
    path: '/',
    ico: 'home',
    authUser: true,
    hideOnCtf: false
  },
  {
    name: 'menu_challenges',
    path: '/challenges',
    ico: 'file-done',
    authOnly: true,
    showOnCtf: true,
    ctfType: 'jeopardy'
  },
  {
    name: 'menu_scenarios',
    path: '/scenarios',
    ico: 'file-protect',
    authOnly: true,
    hideOnCtf: true
  },
  {
    name: 'menu_machines',
    path: '/machines',
    ico: 'cluster',
    authOnly: true,
    hideOnCtf: true
  },
  {
    name: 'menu_ranking',
    path: '/ranking',
    ico: 'bar-chart',
    authUser: true,
    authUserRankingOnly: true,
    hideOnCtf: false
  },
  {
    name: 'menu_quizzes',
    path: '/tests',
    ico: 'question-circle',
    authOnly: true,
    hideOnCtf: true
  }
]

export const settingPathNames = [
  {
    name: 'menu_users',
    path: '/settings/users',
    ico: 'user',
    authAdmin: (settings.normalAdminModules?.includes('users') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('users') && true) || undefined
  },
  {
    name: 'menu_sessions',
    path: '/settings/sessions',
    ico: 'user',
    authAdmin: (settings.normalAdminModules?.includes('sessions') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('sessions') && true) || undefined
  },
  {
    name: 'menu_stacks',
    path: '/settings/stacks',
    ico: 'cluster',
    authAdmin: (settings.normalAdminModules?.includes('stacks') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('stacks') && true) || undefined
  },
  {
    name: 'menu_scenarios',
    path: '/settings/scenarios',
    ico: 'file-protect',
    authAdmin: (settings.normalAdminModules?.includes('scenarios') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('scenarios') && true) || undefined
  },
  {
    name: 'menu_machines',
    path: '/settings/machines',
    ico: 'cluster',
    authAdmin: (settings.normalAdminModules?.includes('machines') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('machines') && true) || undefined
  },
  {
    name: 'menu_quizzes',
    path: '/settings/tests',
    ico: 'question-circle',
    authAdmin: (settings.normalAdminModules?.includes('quizzes') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('quizzes') && true) || undefined
  },
  {
    name: 'menu_logs',
    path: '/settings/logs',
    ico: 'eye',
    authAdmin: (settings.normalAdminModules?.includes('logs') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('logs') && true) || undefined
  },
  {
    name: 'menu_challenges',
    path: '/settings/challenges',
    ico: 'profile',
    authAdmin: (settings.normalAdminModules?.includes('challenges') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('challenges') && true) || undefined
  },
  {
    name: 'menu_challenge_categories',
    path: '/settings/challenge_categories',
    ico: 'profile',
    authAdmin: (settings.normalAdminModules?.includes('challenge_categories') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('challenge_categories') && true) || undefined
  },
  {
    name: 'menu_flags',
    path: '/settings/flags',
    ico: 'flag',
    authAdmin: (settings.normalAdminModules?.includes('flags') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('flags') && true) || undefined
  },
  {
    name: 'menu_vpns',
    path: '/settings/vpns',
    ico: 'ant-cloud',
    authAdmin: (settings.normalAdminModules?.includes('vpns') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('vpns') && true) || undefined
  },
  {
    name: 'menu_vpn_descriptions',
    path: '/settings/vpn_descriptions',
    ico: 'file-text',
    authAdmin: (settings.normalAdminModules?.includes('vpn_descriptions') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('vpn_descriptions') && true) || undefined
  },
  {
    name: 'menu_map_objects',
    path: '/settings/map_objects',
    ico: 'profile',
    authAdmin: (settings.normalAdminModules?.includes('map_objects') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('map_objects') && true) || undefined
  },
  {
    name: 'menu_stack_descriptions',
    path: '/settings/stack_descriptions',
    ico: 'file-text',
    authAdmin: (settings.normalAdminModules?.includes('stack_descriptions') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('stack_descriptions') && true) || undefined
  },
  {
    name: 'menu_maps',
    path: '/settings/maps',
    ico: 'apartment',
    authAdmin: (settings.normalAdminModules?.includes('maps') && true) || undefined,
    authSuperAdmin: (!settings.normalAdminModules?.includes('maps') && true) || undefined
  }
]
